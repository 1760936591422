import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeScreenComponent } from "./welcome/home-screen/home-screen.component";
import { AuthGuard } from "./_helpers/auth.guard";
import { NotAuthGuard } from "./_helpers/not-auth.guard";

const routes: Routes = [
  {
    path: "welcome",
    children: [
      {
        path: "",
        redirectTo: "home",
        pathMatch: "full",
      },
      {
        path: "home",
        component: HomeScreenComponent,
        canActivate: [NotAuthGuard],
      },
      {
        path: "start",
        canActivate: [NotAuthGuard],
        loadChildren: () =>
          import("./welcome/start/start.module").then((m) => m.StartModule),
      },
      {
        path: "menu",
        canActivate: [AuthGuard],
        children: [
          {
            path: "",
            loadChildren: () =>
              import("./welcome/home-menu/home-menu.module").then(
                (m) => m.HomeMenuModule
              ),
          },
          {
            path: "discover-insurrrance",
            loadChildren: () =>
              import(
                "./welcome/home-menu/discover-insurrance/discover-insurrance.module"
              ).then((m) => m.DiscoverInsurranceModule),
          },
          {
            path: "our-products-and-services",
            children: [
              {
                path: "",
                loadChildren: () =>
                  import(
                    "./welcome/home-menu/products-and-services/products-and-services.module"
                  ).then((m) => m.ProductsAndServicesModule),
              },
            ],
          },
        ],
      },
      {
        path: "thankyou",
        loadChildren: () =>
          import("./welcome/exit-screen/exit-screen.module").then(
            (m) => m.ExitScreenModule
          ),
      },
      {
        path: "intro",
        loadChildren: () =>
          import("./welcome/intro-screen/intro-screen.module").then(
            (m) => m.IntroScreenModule
          ),
      },
    ],
  },
  { path: "", redirectTo: "/welcome/home", pathMatch: "full" },
  {
    path: "**",
    redirectTo: "/welcome/home",
    pathMatch: "full",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
