
	export const environment = {
  production: true,
  baseUrl: 'https://software.group-activa.com:44305/activa_server',
  idleBaseUrl: 'https://software.group-activa.com:44305/notify',
  faceUrl: 'https://activaapimanagement.azure-api.net/facial/',
  autoService: "https://software.group-activa.com:44305/costapps/#/step-one/",
  riskService: "https://software.group-activa.com:44305/costapps/#/multirisque-habitation-step1/",
  condoleanceService: "https://software.group-activa.com:44305/costapps/#/condoleance-stepOne/",
  ladyStartupService: "https://software.group-activa.com:44305/costapps/#/ladyStartUp-step1/",
  chatbotService: "https://activa-chatbot.sevengps.net/full/#/seven/load",
  getInsurrance: "https://software.group-activa.com:44305/costapps/#/all-assurance/",
  googleTrackingId: 'UA-199007566-1',
  loginUrl: 'https://activaapimanagement.azure-api.net/showroom/v1/activa_server'
};
