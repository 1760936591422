import { Injectable } from "@angular/core";
import { ProspectLoginDTO, ProspectSignupDTO } from "../_models/prospect-dtos";
import { HttpClient } from "@angular/common/http";
import { PageService } from "./page.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { GlobaldataService } from "./globaldata.service";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private pageService: PageService,
    private router: Router,
    private dialogRef: MatDialog,
    private userData: GlobaldataService,
    private http: HttpClient
  ) {}

  // check if prospect is logged-in or not
  get isProspectLogin() {
    const prosId = localStorage.getItem("ACTIVA_PROSPECT_ID");
    return Boolean(prosId);
  }

  // login prospect
  login(prospect: ProspectLoginDTO) {
    const phone = prospect.prospect_phone;
    return this.http.post(`${environment.loginUrl}/login/prospect`, { phone });
  }
  loginX(phone: string) {
    return this.http.post(`${environment.loginUrl}/login/prospect`, { phone });
  }

  // login prospect
  register(prospect: ProspectSignupDTO) {
    const user = {
      name: prospect.prospect_name,
      email: prospect.prospect_email,
      phone: prospect.prospect_phone,
    };
    return this.http.post(`${environment.loginUrl}/register/prospect`, user);
  }

  // logout prospect
  logout() {
    this.router.navigate(["/welcome/thankyou"]);
  }
}
